import * as React from 'react'
import Img from 'gatsby-image'
import { styles } from './styles'
import CHRArrowButton from './../arrowButton'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import placeholderImage from './../../assets/images/case-studies.jpg'
import desktopIconImage from './../../assets/images/Chirpyest_Member_page_icon@2x.png'
import { ROUTES } from '../../constants'
import { navigate } from 'gatsby'

const getStyle = (isMediaContained: any) => ({
  maxWidth: '100%',
  objectFit: isMediaContained ? 'contain' : 'cover',
  height: '454px',
})

interface FloatImageLeftProps {
  rightChildren: React.ReactNode
  block?: boolean
  withBackground?: boolean
  withoutButton: boolean
  buttonLabel?: string
  t: TFunction
  handleAddChirpyestToBrowserClick?: Function
  goToHowItWorks?: boolean
  image: any
  onLandingMobile?: boolean
  isMediaContained?: boolean
  mediaHasBg: boolean
  isGatsbyImg?: boolean
}

const CHRFloatImageLeftDesign = ({
  rightChildren,
  block,
  withBackground,
  withoutButton = false,
  buttonLabel,
  t,
  handleAddChirpyestToBrowserClick,
  goToHowItWorks,
  image,
  onLandingMobile,
  isMediaContained,
  mediaHasBg,
  isGatsbyImg,
}: FloatImageLeftProps) => {
  const classes = styles()

  let isBgColored = ''

  if (withBackground && onLandingMobile) {
    isBgColored = classes.mobileLandingNoBg
  } else if (!withBackground && onLandingMobile) {
    isBgColored = classes.mobileLandingWithBg
  } else if (withBackground) {
    isBgColored = classes.background
  }

  const mediaStyle: string = isMediaContained && classes.mediaIsContained

  const renderImg = (image: any, isGatsbyImg: boolean) => {
    if (!image) {
      return null
    }

    if (isGatsbyImg) {
      return (
        <Img
          fluid={image}
          alt={t('imageAlts.placeholder')}
          style={getStyle(isMediaContained)}
          imgStyle={getStyle(isMediaContained)}
          className={`${classes.mediaColumnImage} ${mediaStyle}`}
        />
      )
    }
    return (
      <img
        src={image ? image : placeholderImage}
        alt={t('imageAlts.placeholder')}
        className={`${classes.mediaColumnImage} ${mediaStyle}`}
      />
    )
  }

  return (
    <div className={`${classes.leftFloat} ${isBgColored}`}>
      <div className={classes.mediaColumn}>
        <div className={classes.mediaContainer}>
          {renderImg(image, isGatsbyImg)}
        </div>
        {block && (
          <div className={`${classes.blackBox} ${classes.desktop}`}>
            <img
              src={desktopIconImage}
              alt={t('imageAlts.placeholder')}
              className={classes.blackBoxImage}
            />
          </div>
        )}
        {block && (
          <div className={`${classes.blackBox} ${classes.mobile}`}>
            <img
              src={desktopIconImage}
              alt={t('imageAlts.placeholder')}
              className={classes.blackBoxImage}
            />
          </div>
        )}
      </div>
      <article className={classes.textColumn}>
        <div className={classes.textContent}>
          {rightChildren}
          {!withoutButton && (
            <div className={`${classes.flexEnd} ${classes.desktop}`}>
              <CHRArrowButton
                label={buttonLabel ? buttonLabel : t('shared.addChirpyest')}
                onClick={
                  goToHowItWorks
                    ? () => navigate(ROUTES.howItWorks)
                    : handleAddChirpyestToBrowserClick
                }
              />
            </div>
          )}
          {!withoutButton && (
            <div className={`${classes.flexEnd} ${classes.mobile}`}>
              <CHRArrowButton
                label={buttonLabel ? buttonLabel : t('shared.joinChirpyest')}
                onClick={
                  goToHowItWorks
                    ? () => navigate(ROUTES.howItWorks)
                    : () => navigate(ROUTES.join)
                }
              />
            </div>
          )}
        </div>
      </article>
    </div>
  )
}

export default withTranslation()(CHRFloatImageLeftDesign)
