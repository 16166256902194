import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import CHRAccordion from '../accordion'
import axios from '../../axios'
import { ENDPOINTS } from './../../constants'
import CHRSpinner from '../spinner'
import { Typography } from '@material-ui/core'

interface FaqTabProps {
  brand: string
  commission: string
  t: TFunction
}

interface FaqItemProps {
  id: any
  title: string
  brand: string
  commission: string
  HTMLString: string
}

const FaqItem = ({
  id,
  title,
  brand,
  commission,
  HTMLString,
}: FaqItemProps) => {
  return (
    <CHRAccordion
      id={id}
      title={title?.replace('[Brand]', brand?.toLowerCase())}
    >
      <article
        dangerouslySetInnerHTML={{
          __html: HTMLString?.replace('[Commission]', commission).replace(
            '[Brand]',
            brand?.toLowerCase()
          ),
        }}
      />
    </CHRAccordion>
  )
}

const FaqsFeaturedBrandsTab = ({ brand, commission, t }: FaqTabProps) => {
  const [faqs, setFaqs] = useState([{}])
  const [status, setStatus] = useState({
    isLoading: false,
    message: '',
  })

  useEffect(() => {
    ;(async () => {
      setStatus({ message: '', isLoading: true })
      try {
        const response = await axios.get(
          ENDPOINTS.cms.replace(':name', 'faqs-feature-brands')
        )
        const faqs = JSON.parse(response.data.data.content).faqs_feature_brands
        setStatus({ message: '', isLoading: false })
        setFaqs(faqs)
      } catch (error) {
        setStatus({
          message: t('messages.somethingWentWrong'),
          isLoading: false,
        })
      }
    })()
  }, [])

  return (
    <>
      {status.isLoading ? (
        <CHRSpinner />
      ) : status.message ? (
        <Typography variant="h1" component="h6">
          {status.message}
        </Typography>
      ) : (
        faqs?.map((faq: { id: number; title: string; content: string }) => {
          return (
            <FaqItem
              key={faq.id}
              id={faq.id}
              title={faq.title}
              HTMLString={faq.content}
              brand={brand}
              commission={commission}
            />
          )
        })
      )}
    </>
  )
}
export default withTranslation()(FaqsFeaturedBrandsTab)
