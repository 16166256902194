import React, { useState } from 'react'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { FormControl, Hidden } from '@material-ui/core'
import Select from 'react-select'
import { BREAKPOINTS } from '../../constants'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

interface ProductsSortProps {
  options: any
  label: string
  selectedFilter: any
  onFilterChange: () => void
  t: TFunction
}

const CHRProductsSortDesign = ({
  t,
  label,
  options,
  onFilterChange,
  selectedFilter,
}: ProductsSortProps) => {
  const classes = styles()
  const colourStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'transparent',
      width: '100%',
      padding: 0,
      // marginLeft: 10,
      cursor: 'pointer',
      border: 'none !important',
      boxShadow: 'none',
      '&:hover': {
        border: 'none !important',
      },
    }),

    option: styles => {
      return {
        ...styles,
        fontFamily: 'Graphik Regular',
        fontSize: '1rem',
        padding: '5px 0 5px 55px',
        [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
          fontSize: '1.25rem',
        },
      }
    },

    valueContainer: styles => ({
      height: 50,
    }),

    singleValue: styles => ({
      ...styles,
      top: 'unset',
      bottom: -25,
      fontFamily: 'Graphik Regular',
      fontSize: '0.75rem',
      fontWeight: 400,
      color: 'transparent',
    }),

    placeholder: styles => ({
      display: 'none',
    }),

    input: styles => ({
      color: 'transparent',
    }),

    menu: styles => ({
      ...styles,
      width: '100%',
      left: 0,
      overflowX: 'hidden',
    }),
  }

  const deviceType = () => {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <h4 className={classes.sortByText}>
          {/* {label + ' ' + selectedFilter.label} */}
          {deviceType() !== 'mobile'
            ? label + ' ' + selectedFilter.label
            : selectedFilter.label}
          <ArrowDropDownIcon />
        </h4>
        <Select
          defaultValue={selectedFilter}
          value={selectedFilter}
          className={classes.selectList}
          onChange={onFilterChange}
          options={options}
          styles={colourStyles}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          isSearchable={false}
        />
      </FormControl>
    </div>
  )
}

export default withTranslation()(CHRProductsSortDesign)
