import { makeStyles } from '@material-ui/core/styles'
import { COLORS, WIDTH_DIMENSIONS, BREAKPOINTS, FONTS } from '../constants'

export const styles = makeStyles(theme => ({
  seoHeading: {
    fontSize: '16px !important',
    fontFamily: 'Graphik Light !important',
    letterSpacing: '0.00938em !important',
    lineHeight: '1.75 !important',
    marginBottom: '0px !important',
    marginTop: '10px !important',
    fontWeight: 400,
  },
  headingWrapper: {
    width: '100%',
    background: COLORS.whiteStain,
    padding: '40px 0px',
    marginBottom: 40,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      padding: '40px 20px',
    },
  },
  headingContainer: {
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    maxWidth: WIDTH_DIMENSIONS.max,
    margin: 'auto',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  headingTextContainer: {
    width: 'calc(50% - 10px)',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 10,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '100%',
      paddingRight: 0,
    },
    [`& nav.MuiBreadcrumbs-root`]: {
      marginBottom: 15,
    },
    [`& li.MuiBreadcrumbs-li a`]: {
      fontSize: '14px !important',
      fontWeight: 300,
      color: COLORS.black,
      letterSpacing: '0.38px',
      lineHeight: '15px',
      cursor: 'pointer',
      fontFamily: 'Graphik Light',
    },
    [`& li.MuiBreadcrumbs-separator`]: {
      fontSize: '14px !important',
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '15px',
      fontFamily: 'Graphik Light',
      color: COLORS.black,
      marginTop: 5,
    },
    [`& li.MuiBreadcrumbs-li p`]: {
      height: 27.43,
      fontSize: '14px !important',
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '31px',
      fontFamily: 'Graphik Light',
      color: COLORS.black,
    },
  },
  actionButtonsContainer: {
    width: 'calc(50% - 10px)',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '100%',
      paddingLeft: 0,
    },
  },
  headingLogoContainer: {
    display: 'flex',
  },
  headingLogoImage: {
    borderRadius: 999,
    width: 100,
    height: 100,
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      width: 80,
      height: 80,
    },
  },
  headingLogoText: {
    paddingLeft: 21,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      overflowX: 'auto',
    },
  },
  brandTitle: {
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      fontSize: '1.25rem',
    },
  },
  brandName: {
    fontSize: '5rem',
    fontFamily: 'Schnyder XL Light',
    fontWeight: 300,
    textTransform: 'lowercase',
    letterSpacing: 0,
    lineHeight: '65px',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      fontSize: '2.8125rem',
      lineHeight: '50px',
    },
  },
  brandDescription: {
    maxWidth: 585,
    marginLeft: '121px !important',
    fontFamily: 'Graphik Light !important',
    fontSize: '1rem !important',
    letterSpacing: '0px !important',
    lineHeight: '23px !important',
    marginBottom: '0px !important',
    marginTop: '16px !important',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      marginLeft: 101,
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      marginBottom: 20,
      marginLeft: 0,
    },
  },
  visitButtonContainer: {
    maxWidth: 343,
    width: '100%',
    alignSelf: 'flex-end',
    flexGrow: 1,
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      maxWidth: 170,
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      alignSelf: 'center',
      maxWidth: 343,
      marginTop: 20,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      marginTop: 0,
      maxWidth: 232,
    },
  },
  visitButton: {
    textDecoration: 'none',
    padding: '20px 0',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '12px',
    background: COLORS.black,
    textAlign: 'center',
    color: COLORS.white,
    letterSpacing: '1.2px',
    lineHeight: '14px',
    display: 'block',
  },
  filtersContainer: {
    display: 'flex',
    // when add refine change the 2 lines below
    // justifyContent: 'space-between',
    justifyContent: 'flex-end',
  },
  filterButton: {
    width: 'calc(50% - 10px)',
  },
  productsContainer: {
    fontFamily: FONTS.Graphik.GraphikLight,
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: WIDTH_DIMENSIONS.max,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      width: '90%',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      width: '94%',
    },
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  countingFilterContainer: {
    display: 'flex',
    marginBottom: 20,
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      // display: 'none',
    },
  },
  itemsCount: {
    color: COLORS.ashGrey,
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '1rem',
    letterSpacing: '1px',
    lineHeight: '18p',
    textTransform: 'uppercase',
  },
  readMore: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  lineHeight: {
    lineHeight: 1.4375,
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      lineHeight: '26px !important',
    },
  },
  headingWrap: {
    width: '70%',
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: '90%',
    },
  },
  tipMargin: {
    marginBottom: '15px !important',
  },
  headingMargin: {
    marginBottom: 20,
    letterSpacing: 'normal',
    lineHeight: '45px',
    fontFamily: FONTS.Schnyder.SchnyderLLight,
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      lineHeight: '40px !important',
      fontSize: '36px !important',
    },
  },
  firstRow: {
    display: 'flex',
    justifyContent: 'space-around',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      maxWidth: 600,
      margin: 'auto',
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      maxWidth: 375,
    },
  },
  secondRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 25,
    paddingBottom: 30,
    flexWrap: 'wrap',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      paddingTop: 0,
      paddingBottom: 30,
      flexDirection: 'column',
      maxWidth: 600,
      margin: 'auto',
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      maxWidth: 375,
    },
  },
  circle2: {
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      marginLeft: 200,
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      marginLeft: 77,
    },
  },
  circle4: {
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      marginLeft: 200,
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      marginLeft: 87,
    },
  },
  joinLinkSpan: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  tabPanelSection: {
    maxWidth: 700,
    margin: '0px auto',
    [`& .MuiAccordionSummary-content > p`]: {
      fontFamily: FONTS.Graphik.GraphikRegular,
      letterSpacing: 'normal',
      lineHeight: '23px',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      margin: 0,
    },
  },
  chirpyestCreators: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '380px',
    backgroundColor: '#FAFBFD',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      height: '251px',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      height: '214px',
    },
  },
  chirpyestCreatorsHeading: {
    fontSize: '35px !important',
    fontFamily: FONTS.Schnyder.SchnyderLLight,
    lineHeight: '45px',
    letterSpacing: 'normal',
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      fontSize: '30px !important',
      lineHeight: '34px !important',
    },
  },
  filterBar: {
    display: 'flex',
    [`& > div`]: {
      width: '50%',
      backgroundColor: '#FAFBFD',
    },
    [`& > div:nth-child(1)`]: {
      marginRight: 20,
    },
  },
  joinCard: {
    backgroundColor: COLORS.whiteStain,
    height: 454,
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      height: 307,
      paddingLeft: 35,
      paddingRight: 35,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      height: 240,
      paddingTop: 40,
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  joinCardConditional: {
    paddingTop: 129,
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      paddingTop: 85,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      paddingTop: 40,
    },
  },
  seoH2: {
    fontSize: '30px !important',
    fontWeight: 300,
    letterSpacing: '0 !important',
    lineHeight: '34px !important',
    marginBottom: '20px !important',
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      fontSize: '25px !important',
    },
  },
  seoDesc: {
    overflow: 'hidden',
    width: 561.64,
    height: 360,
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      width: 400,
      height: 220,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: 320,
      height: 175,
    },
  },
  seoH2Description: {
    width: 580,
    height: 360,
    paddingRight: 10,
    overflow: 'auto',
    fontSize: '14px !important',
    lineHeight: '24px !important',
    letterSpacing: '0.38px !important',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      width: 400,
      height: 220,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: 320,
      height: 175,
    },
  },
}))
