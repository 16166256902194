import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from './../../constants'

export const styles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      marginBottom: 20,
    },
  },
  circle: {
    backgroundColor: props => props.color,
    display: 'flex',
    color: COLORS.white,
    borderRadius: '50%',
    width: 89,
    height: 89,
    minWidth: 89,
    minHeight: 89,
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: 80,
      height: 80,
      minWidth: 80,
      minHeight: 80,
    },
  },
  label: {
    fontFamily: FONTS.Schnyder.SchnyderLLight,
    fontSize: 30,
  },
  text: {
    fontFamily: FONTS.Schnyder.SchnyderLLight,
    fontSize: 20,
    paddingLeft: 10,
    margin: 0,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      fontSize: 20,
    },
  },
}))
