import React from 'react'
import { styles } from './styles'

interface JoinCircleDesignProps {
  circlelabel: string
  color: string
  children: string
  customStyle?: string
}

const JoinCircleDesign = ({
  circlelabel,
  color,
  children,
  customStyle,
}: JoinCircleDesignProps) => {
  const classes = styles({ color })
  return (
    <div className={classes.wrapper}>
      <div className={`${classes.circle} ${customStyle}`}>
        <p className={classes.label}>{circlelabel}</p>
      </div>
      <p className={classes.text}>{children}</p>
    </div>
  )
}

export default JoinCircleDesign
